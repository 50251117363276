import React from "react";
import { Entity } from "@thewebops/admin-panel";
import { chunk } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import pluralize from "pluralize";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import FormattedTaxRegistrationNumber from "./components/FormattedTaxRegistrationNumber";
import CustomDatePicker from "./components/CustomDatePicker";
import edaStatusHistoryTable from "./components/edaStatusHistoryTable";

export default new Entity({
  name: "product",
  pluralName: "products",
  pathName: "products",
  endpoint: "/admin/products",
  serverName: "product",
  serverPluralName: "products",
  displayName: "Products",
  isFullWidth: true,
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  showSearch: true,
  import: [
    {
      label: "Products sheet",
      enabled: true,
      extensions: ["xls", "csv", "application/vnd.openxmlformats-officedocument", "xlsx"],
      paramName: "file",
      method: "post",
      endpoint: "/admin/products/import",
    },
    {
      label: "Reconcile sheet",
      enabled: true,
      extensions: ["xls", "csv", "application/vnd.openxmlformats-officedocument", "xlsx"],
      paramName: "file",
      method: "post",
      endpoint: "/admin/products/reconciliations/",
    },
  ],
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  customNotifications: {
    import: {
      error: {
        title: "Error Importing Sheet",
        message: (dataObject) => {
          return dataObject.error.message;
        },
      },
    },
  },
  rowActions: [
    {
      label: "Publish/Unpublish",
      name: "publishProduct",
    },
  ],
  globalActions: [
    {
      label: { en: "Download import sheet Template", ar: "تنزيل نموذج" },
      name: "downloadImportTemplate",
    },
    {
      label: { en: "Download Reconcile Template", ar: "تنزيل نموذج الإصلاح" },
      name: "downloadReconcileTemplate",
    },
    {
      label: "Export",
      name: "exportProducts",
    },
  ],
  paginationMode: "reduced",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "gtin",
      label: { en: "GTIN", ar: "رقم البند التجارة العالمي" },
      accessor: "gtin",
      usedToShow: true,
      inList: true,
      type: "string",
      required: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "translated",
      label: { en: "Translated", ar: "مترجم" },
      accessor: "translated",
      type: "select",
      options: [
        {
          label: { en: "true", ar: "مترجم" },
          value: true,
        },
        {
          label: { en: "false", ar: "غير مترجم" },
          value: false,
        },
      ],
      defaultValue: false,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "functional_name",
      label: { en: "Functional Name", ar: "اسم العملي الانجليزي" },
      accessor: "functional_name",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "functional_name_arabic",
      label: { en: "Functional Arabic Name", ar: "اسم العملي العربي" },
      accessor: "functional_name_arabic",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "variant_name",
      label: { en: "Variant Name", ar: "اسم المتغير الانجليزي" },
      accessor: "variant_name",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "variant_name_arabic",
      label: { en: "Variant Arabic Name", ar: "اسم المتغير العربي" },
      accessor: "variant_name_arabic",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "sku_part_number",
      label: "SKU/Part Number",
      accessor: "sku_part_number",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "hs_code",
      label: "HS Code",
      accessor: "hs_code",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "supplier_code",
      label: "Supplier Code",
      accessor: "supplier_code",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "acid",
      label: "ACID",
      accessor: "acid",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "name",
      label: { en: "English Name", ar: "الاسم الانجليزي" },
      accessor: "name",
      usedToShow: true,
      inList: true,
      type: "string",
      required: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "arabic_name",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "arabic_name",
      usedToShow: true,
      inList: true,
      required: false,
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "net_content",
      label: { en: "Net Content", ar: "مقدار المنتج" },
      accessor: "net_content",
      type: "string",
      required: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "net_content_arabic",
      label: { en: "Net Content  Arabic", ar: "مقدار المنتج بالعربي" },
      accessor: "net_content_arabic",
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "published",
      label: { en: "Published", ar: "نشرت" },
      accessor: (dataObject) => (dataObject.published ? "True" : "False"),
      inList: true,
      type: "boolean",
      required: true,
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "active_from",
      label: { en: "Active From", ar: "نشط من" },
      accessor: "active_from",
      inList: true,
      type: "date",
      required: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "active_to",
      label: { en: "Active To", ar: "نشط حتى" },
      accessor: "active_to",
      inList: true,
      hideInEdit: true,
      hideInCreate: true,
      type: "date",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "gtin_record_status",
      label: { en: "Gtin Record Status", ar: "حاله اختصار الشركة العالمي" },
      accessor: "gtin_record_status",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 3,
      mdCol: 3,
      type: "select",
      options: [
        {
          label: { en: "ACTIVE", ar: "نشط" },
          value: "ACTIVE",
        },
        {
          label: { en: "INACTIVE", ar: "غير نشط" },
          value: "INACTIVE",
        },
        {
          label: { en: "DISCONTINUED", ar: "توقف" },
          value: "DISCONTINUED",
        },
      ],
    },
    {
      identifier: "gtin_record_status_template",
      label: { en: "Gtin Record Status Template", ar: "حاله اختصار الشركة العالمي" },
      accessor: "gtin_record_status_template",
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "gcp_id",
      label: { en: "GCP", ar: "اختصار الشركة العالمي" },
      accessor: (dataObject) => dataObject?.gcp?.code,
      formAccessor: (dataObject) => dataObject?.gcp?.id,
      type: "reference",
      isSearchable: true,
      optionsEntity: {
        name: "gcp",
        labelField: "code",
        valueField: "id",
        displayAsLink: true,
      },
      inList: true,
      required: true,
      disabled: (action) => action === "edit",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "owner_company",
      label: { en: "Owner Company", ar: "الشركة المالكة" },
      accessor: (dataObject) => dataObject?.owner_company?.name,
      formAccessor: (dataObject) => dataObject?.owner_company?.id,
      type: "reference",
      optionsEntity: {
        name: "company",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "company_product_attributes",
      label: { en: "Retailer/Account Companies", ar: "شركات التجزئة/الحسابات" },
      accessor: "company_product",
      type: "reference",
      useObjectArray: true,
      isSearchable: true,
      multiple: true,
      lgCol: 3,
      mdCol: 3,
      optionsEntity: {
        name: "company",
        valueField: "id",
        labelField: "name",
        valueLabel: "company_id",
        displayAsLink: true,
        filterParams: [
          {
            name: "inactive",
            value: false,
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "category_id",
      label: { en: "Category", ar: "التصنيف" },
      formAccessor: (dataObject) => dataObject?.category?.id,
      accessor: (dataObject) => dataObject?.category?.name || "None",
      inList: true,
      required: true,
      type: "reference",
      isSearchable: true,
      lgCol: 3,
      mdCol: 3,
      optionsEntity: {
        name: "category",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
        filterParams: [
          {
            name: "category_type",
            value: "brick",
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "brand_id",
      label: { en: "Brand", ar: "الماركة" },
      formAccessor: (dataObject) => dataObject?.brand?.id,
      accessor: (dataObject) => dataObject?.brand?.name || "Unknown",
      inList: true,
      type: "reference",
      isSearchable: true,
      required: true,
      lgCol: 3,
      mdCol: 3,
      optionsEntity: {
        name: "brand",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
    },
    {
      identifier: "company_name",
      label: { en: "Company Name", ar: "اسم الشركة" },
      accessor: "company_name",
      inList: true,
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "target_market",
      label: { en: "Target Market", ar: "السوق المستهدف" },
      accessor: "target_market",
      inList: true,
      type: "string",
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "discontinued_at",
      label: { en: "Discontinued at", ar: "تاريخ التوقف" },
      accessor: "discontinued_at",
      type: "string",
      formComponent: CustomDatePicker,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "tax_registration_number",
      label: { en: "Tax Registration Number", ar: "رقم التسجيل الضريبي" },
      accessor: (dataObject) =>
        dataObject.tax_registration_number
          ? chunk(dataObject.tax_registration_number.toString().split(""), 3)
              .map((x) => x.join(""))
              .join("-")
          : null,
      formComponent: FormattedTaxRegistrationNumber,
      type: "string",
      inList: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "reconcile_status",
      label: "Reconcile Status",
      accessor: "reconcile_status",
      type: "string",
      inList: true,
      hideInEdit: true,
      hideInCreate: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "product_type",
      label: { en: "Product Type", ar: "نوع المنتج" },
      accessor: "product_type",
      type: "string",
      lgCol: 6,
      mdCol: 6,
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      dependee: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "updater",
      label: { en: "Updater", ar: "تم التحديث بواسطة" },
      accessor: (dataObject) => {
        if (dataObject?.updater_id && dataObject?.updater_type && dataObject?.updater_name) {
          return {
            displayValue: `${dataObject?.updater_name} - ${dataObject?.updater_type}`,
            link: `/admin/${pluralize(dataObject.updater_type)?.toLowerCase()}/${dataObject?.updater_id}`,
          };
        } else {
          return null;
        }
      },
      component: ({ value }) => {
        if (!value) return "-";
        const { displayValue, link } = value;

        return <Link to={link}>{displayValue}</Link>;
      },
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "updated_at",
      accessor: (dataObject) => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      label: { en: "Updated at", ar: "تم التحديث في" },
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "created_at",
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      label: { en: "Created at", ar: "أنشئت في" },
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
      lgCol: 3,
      mdCol: 3,
    },
    {
      identifier: "dosage_form_code",
      label: { en: "Dosage Form Code", ar: "كود الجرعة" },
      accessor: "dosage_form_code",
      type: "string",
      lgCol: 3,
      mdCol: 3,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "drug_type",
      accessor: "drug_type",
      label: { en: "Drug Type", ar: "نوع الدواء" },
      type: "string",
      lgCol: 3,
      mdCol: 3,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "drug_license_number",
      label: { en: "Drug License Number", ar: "رقم ترخيص الدواء" },
      accessor: "drug_license_number",
      type: "string",
      lgCol: 3,
      mdCol: 3,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "drug_registration_date",
      label: { en: "Drug registration Date", ar: "تاريخ تسجيل الدواء" },
      accessor: "drug_registration_date",
      type: "string",
      lgCol: 3,
      mdCol: 3,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "approved_packs",
      label: { en: "Approved Packs", ar: "الحزم المعتمدة" },
      accessor: "approved_packs",
      type: "string",
      lgCol: 3,
      mdCol: 3,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "source",
      label: { en: "Source", ar: "المصدر" },
      accessor: "source",
      type: "select",
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 3,
      mdCol: 3,
      options: [
        {
          value: "import_sheet",
          label: "Import Sheet",
        },
        {
          value: "rmq",
          label: "RMQ",
        },
        {
          value: "check_gtin",
          label: "Check GTIN",
        },
        {
          value: "admin_panel",
          label: "Admin Panel",
        },
        {
          value: "g_sync",
          label: "G-Sync",
        },
      ],
    },
    {
      identifier: "image",
      label: { en: "Image", ar: "صورة" },
      accessor: "image",
      type: "image",
      lgCol: 12,
      mdCol: 12,
    },
    ...edaStatusHistoryTable,
  ],
  filters: [
    {
      label: { en: "Product Type", ar: "نوع المنتج" },
      name: "product_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          {
            label: { en: "Normal", ar: "عادي" },
            value: "normal",
          },
          {
            label: { en: "Pharmaceutical", ar: "دوائي" },
            value: "pharmaceutical",
          },
        ],
      },
    },
    {
      label: { en: "Category", ar: "التصنيف" },
      name: "category_ids",
      multiple: true,
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "dropdownCategory",
          labelField: "name",
          valueField: "id",
        },
        isSearchable: true,
      },
    },
    {
      label: { en: "Brand", ar: "الماركة" },
      name: "brand_ids",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "brand",
          labelField: "name",
          valueField: "id",
        },
        isSearchable: true,
      },
    },
    {
      label: { en: "Search Type", ar: "ابحث ب" },
      name: "search_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "normal", label: { en: "Normal", ar: "متعدد" } },
          { value: "full_gtin", label: { en: "Full GTIN", ar: "الباركود بالكامل" } },
        ],
      },
    },
    {
      label: "Reconcile Status",
      name: "reconcile_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "pending", label: "Pending" },
          { value: "reconciled", label: "Reconciled" },
          { value: "failed", label: "Failed" },
        ],
      },
    },
  ],
});
