import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "report",
  pluralName: "reports",
  pathName: "login_activities",
  endpoint: "/admin/reports",
  serverName: "login_activity",
  serverPluralName: "login_activities",
  displayName: "Reports",
  showRowActions: true,
  titleField: "name",
  paginationMode: "reduced",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "user_id",
      label: "User",
      formAccessor: (dataObject) => (dataObject.user ? dataObject.user.id : null),
      accessor: (dataObject) => (dataObject.user ? dataObject.user.name : "None"),
      inList: true,
      type: "reference",
      optionsEntity: {
        name: "dropdownUser",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "ip",
      label: "ip",
      accessor: "ip",
      hideInCreate: true,
      inList: true,
      type: "integer",
      required: true,
    },
    {
      identifier: "created_at",
      label: "Date",
      accessor: "created_at",
      hideInCreate: true,
      inList: true,
      type: "integer",
      required: true,
    },
    {
      identifier: "call_type",
      label: "Type",
      accessor: "call_type",
      hideInCreate: true,
      inList: true,
      type: "string",
      required: true,
      dependee: true,
    },
    {
      identifier: "fetched_products_count",
      label: "Fetched Products Count",
      accessor: (dataObject) => dataObject?.fetched_products_count || "-",
      hideInCreate: true,
      inList: true,
      type: "number",
    },
    {
      identifier: "response",
      label: "Response",
      accessor: "response",
      type: "json",
      dependsOn: "call_type",
      dependsOnValue: ["check_gtin", "eda_check_gtin"],
    },
    {
      identifier: "response_status",
      label: "Response Status",
      accessor: "response_status",
      type: "string",
      dependsOn: "call_type",
      dependsOnValue: ["check_gtin", "eda_check_gtin"],
    },
  ],
  filters: [
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
    },
    {
      label: "Filter",
      name: "type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          {
            value: "check_gtin",
            label: "Check GTIN",
          },
          {
            value: "product_latest",
            label: "Product latest",
          },
          {
            value: "gpc_latest",
            label: "GPC latest",
          },
          {
            value: "get_all_gpcs",
            label: "ALL GPCs",
          },
          {
            value: "get_all_products",
            label: "ALL Products",
          },
          {
            value: "list_eda_products",
            label: "ALL EDA Products",
          },
          {
            value: "get_all_client_products",
            label: "ALL Client Products",
          },
          {
            value: "eda_check_gtin",
            label: "EDA check GTIN",
          },
        ],
      },
    },
    {
      label: "User",
      name: "user_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "dropdownUser",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
