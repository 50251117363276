import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import { chunk } from "lodash";
import FormattedTaxRegistrationNumber from "./components/FormattedTaxRegistrationNumber";
import { push } from "connected-react-router";
import updateGcpsAction from "./formModals/updateGcps";
import migrateGcpsAction from "./formModals/migrateGcps";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import errorMessage from "./components/errorMessage";
import historyTable from "../../components/historyTable";

export default new Entity({
  name: "company",
  pluralName: "companies",
  pathName: "companies",
  endpoint: "/admin/companies",
  serverName: "company",
  serverPluralName: "companies",
  displayName: "Companies",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: false,
  showSearch: true,
  import: {
    enabled: true,
    extensions: ["xls", "csv", "application/vnd.openxmlformats-officedocument", "xlsx"],
    paramName: "file",
    method: "post",
    endpoint: "/admin/companies/imports",
  },
  globalActions: [
    {
      label: { en: "Download import sheet Template", ar: "تنزيل نموذج الإدراج" },
      name: "downloadImportTemplate",
    },
    {
      label: "Export Company's Products",
      name: "companyProductsReports",
      customFunction: (dispatch, item) => {
        dispatch(push("companyProductsReports"));
      },
    },
    {
      label: "Export Products Count",
      name: "exportProductCount",
      customFunction: (dispatch, item) => {
        dispatch(push("exportProductCounts"));
      },
    },

    {
      label: "Export",
      name: "exportCompanies",
    },
  ],

  rowActions: [
    ...updateGcpsAction,
    ...migrateGcpsAction,
    {
      label: "Publish All Products",
      name: "publishAllProducts",
      active: (dataObject) => dataObject?.company_type === "account",
    },
  ],
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,

  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "warning_message",
      label: { en: "Warning Message", ar: "رساله تخذيريه" },
      accessor: "warning_message",
      formAccessor: "warning_message",
      hideInCreate: true,
      hideInEdit: true,
      inList: false,
      hideInShow: true,
    },
    {
      identifier: "error_message",
      label: { en: "Error Message", ar: "رساله تخذيريه" },
      accessor: "error_message",
      formAccessor: "error_message",
      formComponent: errorMessage,
      hideInCreate: true,
      hideInEdit: true,
      inList: false,
      hideInShow: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "arabic_name",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "arabic_name",
      inList: true,
      type: "string",
    },
    {
      identifier: "tax_registration_number",
      label: { en: "Tax Registration Number", ar: "رقم التسجيل الضريبي" },
      required: false,
      accessor: (dataObject) =>
        dataObject.tax_registration_number
          ? chunk(dataObject.tax_registration_number.toString().split(""), 3)
              .map((x) => x.join(""))
              .join("-")
          : null,
      formComponent: FormattedTaxRegistrationNumber,
      inList: true,
      type: "string",
      valuesRejectedOnSubmit: [""],
    },
    {
      identifier: "company_type",
      label: { en: "Company Type", ar: "نوع الشركة" },
      accessor: "company_type",
      inList: true,
      type: "select",
      options: [
        { value: "account", label: { en: "Account", ar: "حساب" } },
        { value: "retailer", label: { en: "Retailer", ar: "بائع التجزئة" } },
      ],
      required: true,
    },
    {
      identifier: "inactive",
      label: { en: "Inactive", ar: "غير نشط" },
      accessor: "inactive",
      inList: true,
      type: "select",
      options: [
        { value: true, label: "true" },
        { value: false, label: "false" },
      ],
      defaultValue: false,
    },
    {
      identifier: "my_gs1_company_id",
      label: "MyGS1 Company ID",
      accessor: "my_gs1_company_id",
      type: "string",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "created_at",
      label: { en: "Created at", ar: "أنشئت في" },
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "updated_at",
      label: { en: "Updated at", ar: "تم التحديث في" },
      accessor: (dataObject) => moment(dataObject.updated_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "active_to",
      label: "Gcps' Active To",
      accessor: "active_to",
      type: "date",
      hideInEdit: true,
      hideInCreate: true,
      hideInShow: true,
      disablePast: true,
    },
    {
      identifier: "gcp_ids",
      label: "Gcps",
      type: "reference",
      isSearchable: true,
      disapled: true,
      multiple: true,
      required: true,
      hideInEdit: true,
      hideInCreate: true,
      hideInShow: true,
      disabled: (action, data, location, state) => state?.migrate_all == true,
      optionsEntity: {
        name: "gcp",
        labelField: "code",
        valueField: "id",
        filterParams: [
          {
            name: "company_id",
            value: ["state", "id"],
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "migrate_all",
      label: "Migrate All",
      type: "toggle",
      hideInEdit: true,
      hideInCreate: true,
      hideInShow: true,

      toggleConfig: {
        on: true,
        off: false,
      },
      defaultValue: true,
    },
    {
      identifier: "target_company_id",
      label: "Target Company",
      type: "reference",
      disapled: true,
      isSearchable: true,
      required: true,
      hideInEdit: true,
      hideInCreate: true,
      hideInShow: true,
      optionsEntity: {
        name: "company",
        labelField: "name",
        valueField: "id",
        filterParams: [
          {
            name: "company_type",
            value: "account",
            type: "both",
          },
        ],
      },
    },
    ...historyTable,
  ],

  filters: [
    {
      label: { en: "Company Type", ar: "نوع الشركة" },
      name: "company_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "account", label: { en: "Account", ar: "حساب" } },
          { value: "retailer", label: { en: "Retailer", ar: "بائع التجزئة" } },
        ],
      },
    },

    {
      label: { en: "Inactive", ar: "غير نشط" },
      name: "inactive",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "true", label: { en: "True", ar: "نعم" } },
          { value: "false", label: { en: "False", ar: "لا" } },
        ],
      },
    },
  ],
});
