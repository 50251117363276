import React from "react";

import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "user",
  pluralName: "users",
  pathName: "users",
  endpoint: "/admin/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: "Users",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "بريد الالكتروني" },
      accessor: "email",
      inList: true,
      type: "email",
      disabled: (action) => action === "edit",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "password",
      label: "Password",
      accessor: "password",
      hideInShow: true,
      type: "password",
      valuesRejectedOnSubmit: [""],
    },
    {
      identifier: "password_confirmation",
      label: "Password Confirmation",
      accessor: "password_confirmation",
      hideInShow: true,
      type: "password",
      valuesRejectedOnSubmit: [""],
    },
    {
      identifier: "active",
      label: "Active",
      accessor: (dataObject) => (dataObject?.active ? true : false),
      hideInCreate: true,
      inList: true,
      type: "select",
      options: [
        {
          value: true,
          label: { en: "Active", ar: "نشط" },
        },
        {
          value: false,
          label: { en: "Inactive", ar: "غير نشط" },
        },
      ],
    },
    {
      identifier: "restrict_access",
      label: "Restrict Access",
      accessor: (dataObject) => (dataObject?.restrict_access ? true : false),
      inList: true,
      type: "select",
      dependee: true,
      required: true,
      defaultValue: false,
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    {
      identifier: "whitelisted_ips",
      label: "Whitelisted IPs",
      accessor: "whitelisted_ips",
      inList: true,
      type: "string",
      dependsOn: "restrict_access",
      dependsOnValue: true,
    },
    {
      identifier: "api_access",
      label: "API Access",
      accessor: "api_access",
      type: "checkbox",
      checkboxConfig: {
        format: "array",
      },
      //TODO: Remove this custom component when the checkbox is fixed
      component: ({ value, field }) => {
        if (!value) return "_";

        const keyValueOptions = field.options.reduce((accum, option) => {
          accum[option.value] = option.label;
          return accum;
        }, {});
        const displayValues = value.map((item) => keyValueOptions[item]).join(", ");
        return <span>{displayValues}</span>;
      },
      options: [
        { value: "gi", label: "GI" },
        { value: "grp", label: "GRP" },
      ],
    },

    {
      identifier: "api_roles",
      label: "API Roles",
      accessor: "api_roles",
      type: "checkbox",
      checkboxConfig: {
        format: "array",
      },
      //TODO: Remove this custom component when the checkbox is fixed
      component: ({ value, field }) => {
        if (!value) return "_";

        const keyValueOptions = field.options.reduce((accum, option) => {
          accum[option.value] = option.label;
          return accum;
        }, {});
        const displayValues = value.map((item) => keyValueOptions[item]).join(", ");
        return <span>{displayValues}</span>;
      },
      options: [
        { value: "gi_user", label: "GI User" },
        { value: "gs1_client", label: "GS1 Client" },
        { value: "eda_user", label: "EDA User" },
      ],
    },

    {
      identifier: "sales_channel_users_attributes",
      label: { en: "Sales Channels", ar: "اختصار الشركة العالمي" },
      lgCol: 12,
      mdCol: 12,
      accessor: "sales_channel_users_attributes",
      type: "transferList",
      transferListConfig: {
        accessor: "sales_channel_users",
        showSearch: true,
      },
      optionsEntity: {
        name: "salesChannel",
        valueField: "id",
        labelField: "channel_name",
        valueLabel: "sales_channel_id",
        displayAsLink: true,
        required: true,
        filterParams: [
          {
            name: "user_id",
            value: ["params", "id"],
            type: "both",
          },
        ],
      },
    },
  ],
  filters: [
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
    },
  ],
});
